$text-muted: #6c757d !important;

.btn-no-style {
  background: none;
  border: 0;
  padding: 0;
  text-decoration: none;

  &:disabled {
    &, > * {
      color: $text-muted;
      cursor: not-allowed !important;
    }
  }
}

.disabled-by-permission {
  color: $text-muted;
  cursor: not-allowed;

  &:not(:input) {
    pointer-events: none;
  }

  &.mat-mdc-menu-item[disabled] {
    cursor: not-allowed;
    pointer-events: all;
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.f-right {
  margin-left: auto;
}

.f-fill {
  height: 100%;
  margin: 0px;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}
