
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Thin.ttf) format('ttf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-ThinItalic.ttf) format('ttf');
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Light.ttf) format('ttf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-LightItalic.ttf) format('ttf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Regular.ttf) format('ttf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Italic.ttf) format('ttf');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Medium.ttf) format('ttf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-MediumItalic.ttf) format('ttf');
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Bold.ttf) format('ttf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-BoldItalic.ttf) format('ttf');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-Black.ttf) format('ttf');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'Roboto';
  src: url(../fonts/Roboto/Roboto-BlackItalic.ttf) format('ttf');
  font-style: italic;
  font-weight: 900;
}
