.ngx-datatable.stil {
    box-shadow: none;
    background: #454545;
    border: 1px solid #414141;
    color: #fff;
    font-size: 13px;

    .datatable-header {
        background: #202020;
        color: #d6d6d6;

        .datatable-header-cell {
            text-align: left;
            padding: 0.5rem 1.2rem;
            font-weight: bold;

            .datatable-header-cell-label {
                line-height: 24px;
            }
        }
    }

    .datatable-body {
        background: #353535;

        .datatable-body-row {
            border-top: 1px solid #2f3646;

            .datatable-body-cell {
                text-align: left;
                padding: 0.5rem 1.2rem;
                vertical-align: top;
            }

            &:hover {
                background: #171b24;
                transition-property: background;
                transition-duration: 0.3s;
                transition-timing-function: linear;
            }

            &:focus {
                background-color: #372323;
            }

            &.active {
                background-color: #808080;
                color: #fff;
            }
        }
    }

    .datatable-footer {
        background: #202020;
        color: #ebebeb;
        margin-top: -1px;

        .page-count {
            line-height: 50px;
            height: 50px;
            padding: 0 1.2rem;
        }

        .datatable-pager {
            margin: 0 10px;
            vertical-align: top;

            ul {
                li {
                    margin: 10px 0px;

                    &:not(.disabled) {

                        &.active a,
                        &:hover a {
                            background-color: #a8a8a8;
                            font-weight: bold;
                        }
                    }
                }
            }

            a {
                height: 22px;
                min-width: 24px;
                line-height: 22px;
                padding: 0;
                border-radius: 3px;
                margin: 0 3px;
                text-align: center;
                vertical-align: top;
                text-decoration: none;
                vertical-align: bottom;
                color: #535353;
            }

            .datatable-icon-left,
            .datatable-icon-skip,
            .datatable-icon-right,
            .datatable-icon-prev {
                font-size: 18px;
                line-height: 27px;
                padding: 0 3px;
            }
        }
    }

    .datatable-summary-row {
        .datatable-body-row {
            background-color: #14171f;

            &:hover {
                background-color: #14171f;
            }

            .datatable-body-cell {
                font-weight: bold;
            }
        }
    }
}
