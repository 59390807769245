$white-100: #EEE;
$white-200: #BBB;
$white-300: #b4b4b4;

$grey-100: #777;
$grey-200: #555;
$grey-300: #444;

$stil-red: #b40f09;
$stil-dark-red: #660805;
$stil-red-light: #d8a6a4;

$text-color: #222;
$subtle-text-color: #707070;

$border-thin: 1px solid #999;
